import React from 'react'
import {Form, Input, Button, Checkbox} from 'antd';
import {LocaleContext} from "../../../context/LocaleContext";
import language from "../../../../data/language.yaml";
import {post} from "../../../services/api";
import {navigate} from "gatsby";
import {Location} from "@reach/router";

class NewsletterSignupForm extends React.Component {
    state = {
        allowSubmit: true,
        errors: [],
        showForm: true
    }

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    onSubmitCallback = (error, response) => {
        if (response.data.status === 'error') {
            this.setState({allowSubmit: true, errors: response.data.errors})
        } else if (response.data.status === 'success' && response.data.errors.length === 0) {
            this.setState({allowSubmit: false, showForm: false});
        }
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(this.onValidateCallback);
    }

    onValidateCallback = (errors, values) => {
        if (errors === null) {
            this.setState({allowSubmit: false});
            post('/wp-json/brew/v1/sign-up/', this.props.form.getFieldsValue(), this.onSubmitCallback);
        }
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        let lang = language.newsletterSignup;
        let current = this.context.locale;

        let content = false;
        if (this.state.showForm) {
            content = <Form onSubmit={this.onSubmit}>
                { this.state.errors && (
                    <ul>
                        {this.state.errors.map((error, index) => (<li key={"error-" + index}>{error}</li>))}
                    </ul>
                )}
                <Form.Item>
                    {getFieldDecorator('first_name', {
                        rules: [{ required: true, message: lang.firstName.error[this.context.locale] }],
                    })(
                        <Input
                            placeholder={lang.firstName[this.context.locale]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('last_name', {
                        rules: [{ required: true, message: lang.lastName.error[this.context.locale] }],
                    })(
                        <Input
                            placeholder={lang.lastName[this.context.locale]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: lang.emailAddress.error[this.context.locale] }],
                    })(
                        <Input
                            type="email"
                            placeholder={lang.emailAddress[this.context.locale]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('disclaimer', {
                        valuePropName: 'checked',
                        initialValue: false,
                        rules: [{
                            required: true,
                            message: lang.disclaimer.error[this.context.locale],
                            type:     "boolean",
                            transform: value => (value || undefined)
                        }],
                    })(<Checkbox><span dangerouslySetInnerHTML={{__html: lang.disclaimer[this.context.locale]}} /></Checkbox>)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn btn-secondary">
                        <span>{lang.signUp[this.context.locale]}</span>
                        <span className="icon arrow"></span>
                    </Button>
                </Form.Item>
            </Form>
        } else {
            content = <Location>
                {({location}) => {
                    let path = current === 'en' ? 'thank-you' : 'diolch';
                    navigate(location.pathname + path + '/');
                }}
            </Location>
        }

        return content;
    }
}

NewsletterSignupForm.contextType = LocaleContext;

export default Form.create()(NewsletterSignupForm);
